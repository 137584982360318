import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { MessageService } from 'src/app/services/message/message.service';
// import { LoadingScreenComponent } from "../../components/loading-screen/loading-screen.component";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email = '';
  password = '';
  lang  = 'fr';
  confirmError = false;
  submitted = false;
  hide = true;
  error = '';
  activated = false;
  message = "Votre compte est en cours d'activation";
  messageEn = "Your account is being activated";
  token = '';
  
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService
    ) { }

  Login() {
    // this.loading = true;
    //console.log('you are logging in ' + this.email + ' ' + this.password);
    this.messageService.getTranslate().subscribe((data) => {
      this.lang = data;
      if (this.lang == 'fr' && this.error == 'incorrect email / password') {
        this.error  = "email / mot de passe incorrect";
      }
      else if (this.lang == 'en' && this.error == 'email / mot de passe incorrect') {
        this.error = "incorrect email / password";
      }
    });

    this.authService.login( (this.email).toLowerCase(), this.password)
    .pipe( first() )
    .subscribe(
      data => {
        //console.log('you are logging in ' + this.email + ' ' + this.password);
        this.router.navigate(['/homes']);
        this.authService.obconnect.next(true);
        // console.log(data);
      },
      err => {
        //console.log('login erreur ===> '+JSON.stringify(err));
        const error = err.error;
        const errorS = error.message;
        const errorD = error.detail;
        if (!errorD ) {
          this.error = (this.lang == 'fr' ? 'email / mot de passe incorrect' : 'incorrect email / password');
        } else if (!errorS) {
          this.error = errorD;
          this.confirmError = true;
        }
        //console.log(error);
        // console.log(errorS);
        // console.log(errorD);
        // this.loading = false;
      }
    );
  }

  isIOS(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }

  ngOnInit() {
    console.log("ios "+this.isIOS());
    /*
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const currentUrl = window.location.href;
    console.log('URL actuelle :', currentUrl);*/
    const idParam = this.route.snapshot.paramMap.get('ID');
    console.log("idParam : "+idParam)
    this.route.paramMap.subscribe(params => {
      var ID = params.get('ID');
      console.log("id : "+ID)
      //console.log('getCurrentProject paramètre id ==> '+params.get('ID'));
      //console.log('getCurrentProject paramètre specMsg ==> '+params.get('specMsg'));
      var specMsg = params.get('specMsg');
      console.log("specMsg : "+specMsg)
      var openmsg = params.get('openmsg');
      console.log("openmsg : "+openmsg)
      var idmsg = params.get('idmsg');
      console.log("idmsg : "+idmsg)
    });

    /*
    if(screenWidth < 480) {
        window.location.href = 'com.facework://';
        setTimeout(() => {
          // Vérifie si l'utilisateur est toujours sur la même page (l'application n'est pas installée)
          if (window.location.href.indexOf('com.facework') !== 0) {
            // Redirige vers le lien de téléchargement de l'App Store ou Google Play Store en fonction de la plateforme
            if (this.isIOS()) {
              window.location.href = 'https://apps.apple.com/fr/app/face2faces/id1557480419'; // Lien de l'App Store iOS
            } else {
              window.location.href = 'https://play.google.com/store/apps/details?id=com.facework'; // Lien du Google Play Store Android
            }
          }
        }, 2000);
    }*/
    if (this.authService.logIn) {
      this.router.navigate(['/']);
    }
    this.route.paramMap.subscribe(params => {
      if(params.get('token')){
        this.token = params.get('token').replace("https:","");
        console.log("token", this.token);
        this.activateAccount(this.token);
      }
    });
  }

  activateAccount(userToken){
    //console.log('your userToken ' + userToken);
    this.authService.confirm(userToken)
     .subscribe(
       data => {
         //console.log('you are logging in ' + this.email + ' ' + this.password);
         //alert('Votre compte est activé, vous pouvez maintenant vous connecter.');
         this.activated = true;
         if (this.lang == 'fr') {
           this.message = 'Votre compte est activé. Vous pouvez maintenant vous connectez.';
         }
         else if (this.lang == 'en') {
           this.messageEn = 'Your account is activated. You can now log in.';
         }
       },
       err => {
         //console.log('login erreur ===> '+JSON.stringify(err));
         this.message = "";
         //this.message = "Une erreur s'est produite";
         this.messageEn = '';
         //this.messageEn = 'An error has occurred';
 
         const error = err.error;
         const errorS = error.message;
         const errorD = error.detail;
         const errordescription = err.error['hydra:description'];
         if (errordescription) {
           this.error = errordescription;
         }
         else if (errorD){  
           this.error = JSON.stringify(errorD);
         }else if (errorS) {
           this.error = JSON.stringify(errorS);
         } else {
           this.error = JSON.stringify(error);
         }
 
   
         //console.log(error);
         // console.log(errorS);
         // console.log(errorD);
         // this.loading = false;
       }
     );
   }

}
