import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-un-reg-menu',
  templateUrl: './un-reg-menu.component.html',
  styleUrls: ['./un-reg-menu.component.scss']
})
export class UnRegMenuComponent implements OnInit {

  invited = false;

  constructor() { 
    if(window.location.href.endsWith("/CompanyRegister")){
      this.invited = true;
    }
  }

  getInvited(){
    return window.location.href.endsWith("/CompanyRegister")
  }

  isLoginPage(){
    return !(window.location.href.endsWith("/login"));
  }

  ngOnInit() {
    
  }

  scrollToElement($element): void {
    var element = document.getElementById("target");
    element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  redirectToFace(){
    window.location.href = 'https://face2faces.fr/';
  }

}
