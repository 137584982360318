import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Observer } from 'rxjs';
import { Message } from 'src/app/services/socket/model/message';
import { Event } from 'src/app/services/socket/model/event';

import * as socketIo from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { MessageService } from '../message/message.service';

//const SERVER_URL = 'http://api.face2faces.fr:83/';

const SERVER_URL = environment.SERVER_URL;

@Injectable()
export class SocketService {
    private socket;

    constructor(private messageService: MessageService){
      this.initSocket();
      this.socket.on('disconnect', () => {
        console.log('Déconnexion détectée. Tentative de reconnexion...');
        this.reconnect();
      });
      this.socket.on('reconnect_attempt', () => {
        console.log('Tentative de reconnexion...');
      });
    
      this.socket.on('reconnect', (attemptNumber: number) => {
        console.log(`Reconnecté après ${attemptNumber} tentatives.`);
        this.reSubscribeToProjects();
      });
    
      this.socket.on('reconnect_failed', () => {
        console.log('Toutes les tentatives de reconnexion ont échoué.');
      });
    }

    reSubscribeToProjects(): void {
      this.subscribeToRoom("0");
      const storedProjects = this.messageService.getListProject();
      if (storedProjects) {
        storedProjects.forEach(value => {
          // Réabonner l'utilisateur à chaque projet après la reconnexion
          this.subscribeToRoom(value.id);
        });
      }
    }

    private reconnect(): void {
      // Implémentez votre logique de réconnexion ici
      // Par exemple, vous pouvez essayer de vous reconnecter après un certain délai
      setTimeout(() => {
        this.socket.connect();
      }, 5000); // Réessayez après 5 secondes (ajustez selon vos besoins)
    }

    public initSocket(): void {
        //this.socket = socketIo(SERVER_URL);
        this.socket = socketIo(SERVER_URL, {
          reconnection: true,  // Activer la reconnexion automatique
          reconnectionDelay: 1000,  // Délai initial de reconnexion (en millisecondes)
          reconnectionAttempts: Infinity,  // Nombre maximal de tentatives de reconnexion (-1 pour une reconnexion infinie)
          timeout: 20000,  // Délai maximal avant de considérer la tentative de connexion comme un échec (en millisecondes)
          autoConnect: true
        });
    }

    public send(message: Message): void {
        this.socket.emit('message', message);
    }

    public sendInstant(message: Message): void {
      this.socket.emit('message', message, message.action);
  }

    public onMessage(): Observable<Message> {
        return new Observable<Message>(observer => {
            this.socket.on('message', (data: Message) => observer.next(data));
        });
    }

    public onEvent(event: Event): Observable<any> {
        return new Observable<Event>(observer => {
            this.socket.on(event, () => observer.next());
        });
    }

    public joinRoom(matchId) {
        try {
          //this.socket = io.connect('http://localhost:3000');
          this.socket.emit('join room', matchId.toString());
        } catch (e) {
         console.log('Could not connect socket.io');
        }
    }

    //------------------------
      subscribeToRoom(roomName: string) {
        this.socket.emit('subscribe', roomName);
      }
    
      unsubscribeFromRoom(roomName: string) {
        this.socket.emit('unsubscribe', roomName);
      }
    
      sendMessage(message: string, roomName: string) {
        this.socket.emit('message', message, roomName);
      }
    
      //onMessage()
      onMessageInstant() {
        return this.socket.fromEvent('message');
      }

      connect() {
        this.socket.connect();
      }


}

