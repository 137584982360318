import { Component, AfterViewInit, ViewChild, Input,ElementRef, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { merge, Observable, of as observableOf, Subject } from 'rxjs';
import { catchError, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MessageService } from 'src/app/services/message/message.service';
import { FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { CoreServiceService } from 'src/app/services/core/core-service.service';


export interface searchFilter {
  transmitter: string;
  recipient: string;
  projectId: string;
  dateBefore: any;
  dateDeadline: any;
  dateAfter: any;
  tag: string;
  statut: boolean;
  type: string;
  keyWord: string;
  smartNumber: string;
}

@Component({
  selector: 'app-dashboardinstcontent',
  templateUrl: './dashboardinstcontent.component.html',
  styleUrls: ['./dashboardinstcontent.component.scss']
})
export class DashboardinstcontentComponent implements AfterViewInit, OnDestroy, OnInit {
 
  messages: any = [];
  dataSource: any;
  resultsLength = 0;
  perPage = 30;
  isLoadingResults = false;
  isRateLimitReached = false;
  lastDate = new Date('01/01/4970');
  filterChoosen = null;
  firstLoad = true;

  displayedColumns: string[] = [
    'Date',
    'Expéditeur',
    'Projet',
    'consult'
  ];
  
  hiddenSearchEngin = true;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @Output() EventLaunchSearch = new EventEmitter();
  @Output() EventLaunchSearchDoc = new EventEmitter();
  @Input() ged: boolean = false;

  transmitter = null;
  recipient = null;
  projectId = null;
  dateBefore = null;
  dateAfter = null;
  dateDeadline = null;
  tag = null;
  statut = null;
  type = null;
  keyWord = null;
  smartNumber = null;
  modeDoc = false;
  //contacts: Array<any> = [];
  contactsExp: Array<any> = [];
  contactsDest: Array<any> = [];
  projectLists: Array<any> = [];
  projectTags: any;
  chosenFilter: searchFilter;
  currentUser = null;
  saveCtc = null;
  saveCtcTransmitter = null;
  saveProject = null;
  saveTheme   = null;
  secteur = "";
  public transmitterCtrl: FormControl = new FormControl();
  public recipientCtrl: FormControl = new FormControl();
  public projectCtrl: FormControl = new FormControl();
  public themeCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  recentProject = null;
  hideNav = Number(localStorage.getItem('hideNav'));

  constructor(private location: Location, private coreService: CoreServiceService, private authService: AuthService, private router: Router, private route: ActivatedRoute, private messageService: MessageService) { 
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    delete currentUser.token;
    delete currentUser.refresh_token;
    delete currentUser.roles;
    this.currentUser = currentUser;
    this.route.queryParams.subscribe(params => {
      this.recentProject = params['projet'];
    });
  }


  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnInit(){
    this.getAllMessages(null);
    this.secteur = localStorage.getItem('secteur');
  
    /*this.getContact();
    this.loadProject(); */

    this.authService.closeBloc.subscribe((term) =>{ 
      if(term == 1){
        this.resetSearch();
      }
      //console.log('dans le smarnumber idmessage ==> '+idMessage);
    });

    this.authService.obprojectChosen.subscribe((idMessage) =>{ 
      this.smartNumber = idMessage;
      //console.log('dans le smarnumber idmessage ==> '+idMessage);
    });

    this.coreService.toggleSidebar.subscribe((res) => {
      this.hideNav = Number(res);
    });

    this.ged = false;
    this.hiddenSearchEngin = true;

  }

  ngAfterViewInit() {  

    this.recipientCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRecipient();
      });

    this.transmitterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterTransmitter();
    });


    this.projectCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProject();
      });

    this.themeCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.themeProject();
      });
   

  }

  redirectToHistoFace(){
    this.router.navigate(['/dashboard']);
  }


 loadFilterElement(){
    //console.log("on loadFilterElement");
    if (this.firstLoad == true) {
      this.getContact();
      this.loadProject();
    }
    this.firstLoad = false;
  }

  fcthiddenSearchEngin(){
    if (this.hiddenSearchEngin == false)
      this.hiddenSearchEngin = true;
    else
      this.hiddenSearchEngin = false;
  }

  protected filterRecipient() {
    if(this.saveCtc == null){
      this.saveCtc = this.contactsDest;
    }

    let search = this.recipientCtrl.value;
    if (!search) {
      this.contactsDest = this.saveCtc;
      return;
    } else {
      search = search.toLowerCase();
      this.contactsDest = this.saveCtc.filter(bank => bank.givenName.toLowerCase().indexOf(search) > -1 || bank.familyName.toLowerCase().indexOf(search) > -1 );
    }
  }

  protected filterTransmitter(){
    if(this.saveCtcTransmitter == null){
      this.saveCtcTransmitter = this.contactsExp;
    }

    let search = this.transmitterCtrl.value;
    if (!search) {
      this.contactsExp = this.saveCtcTransmitter;
      return;
    } else {
      search = search.toLowerCase();
      this.contactsExp = this.saveCtcTransmitter.filter(bank => bank.givenName.toLowerCase().indexOf(search) > -1 || bank.familyName.toLowerCase().indexOf(search) > -1 );
    }
  }

  protected filterProject() {
    if(this.saveProject == null){
      this.saveProject = this.projectLists;
    }

    let search = this.projectCtrl.value;
    if (!search) {
      this.projectLists = this.saveProject;
      return;
    } else {
      search = search.toLowerCase();
      this.projectLists = this.saveProject.filter(bank => bank.libelle.toLowerCase().indexOf(search) > -1);
    }
  }

  protected themeProject() {
    // console.log("calling themeProject");
    if(this.saveTheme == null){
      this.saveTheme = this.projectTags;
      // console.log("in themeProject", this.saveTheme);
    }else{
      // console.log("else themeProject", this.projectTags);
    }

    let search = this.themeCtrl.value;
    if (!search) {
      this.projectTags = this.saveTheme;
      return;
    } else {
      search = search.toLowerCase();
      this.projectTags = this.saveTheme.filter(bank => bank.libelle.toLowerCase().indexOf(search) > -1);
    }
  }
  

  dataMap(data: any, first = false) {

    var strId = data['project']['@id']; 
    var splittedID = strId.split("/"); 
    var idProject = splittedID[splittedID.length-1];  
    //var listTag : any[] = data['tags'];
    var date = data['dateCreated']; 
    var isNewDay = this.isNewDay(date);

    const message: any = {
      'id': idProject,
      'idmsg': data['id'],
      'project': data['project'],
      'first': first,
      //'tag': (listTag[0] ? listTag[0].libelle  : '' ),
      'date': date,
      'typeParse': 'QUESTION',
      'type': data['type'],
      'sender': (data['author'] ? data['author']['fullName'] : ''),
      'filegeneric': data['filegeneric'],
      'isNewDay' : isNewDay
    };

    return message;
  }

 public getAllMessages(searchChoose) {

    this.paginator.pageIndex = 0;
    this.filterChoosen = searchChoose;

    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.authService.getAllInstMessages(this.paginator.pageIndex, this.perPage, this.filterChoosen);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;    

          this.resultsLength = data['hydra:totalItems']; 

          return data['hydra:member'];
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => { 
        //console.log({test1111:data})
        //console.log('contenu de data '+ JSON.stringify(data));
        this.messages = [];
        this.lastDate = new Date('01/01/4970');
        data=data.filter(item=>!item.action)
        var j = 0;
        var first = false;
        data.forEach(element => {
          j++;
          if(j == 1) first = true;
          this.messages.push(this.dataMap(element, first));
          first = false;
        });

        let afterIsNewDay = false;
        this.messages.reverse().forEach((element, index) => {
          if(afterIsNewDay || index == 0){
            this.messages[index]["afterIsNewDay"] = true;
            afterIsNewDay = false;
            //console.log("afterIsNewDay")
          }
          if(element.isNewDay == true){
            afterIsNewDay = true;
          }
        });

        this.dataSource = this.messages.reverse(); 
         

      });

  }

  isRoundFirst(afterIsNewDay, isNewDay){
    if(afterIsNewDay && isNewDay){
      return "divcontaine afterIsNewDayBL afterIsNewDayTL";
    }else if(afterIsNewDay){
      return "divcontaine afterIsNewDayBL";
    }else if(isNewDay){
      return "divcontaine afterIsNewDayTL";
    }
    return "divcontaine";
  }

  isRoundLast(afterIsNewDay, isNewDay){
    if(afterIsNewDay && isNewDay){
      return "divcontaine afterIsNewDayBR afterIsNewDayTR";
    }else if(afterIsNewDay){
      return "divcontaine afterIsNewDayBR";
    }else if(isNewDay){
      return "divcontaine afterIsNewDayTR";
    }
    return "divcontaine";
  }

  isNewDay(messageDate){
    let currentDate = new Date(messageDate.substring(0,10)); 
    //console.log('this.lastDate ==> '+this.lastDate);
    //console.log('currentDate ==> '+currentDate);
    if(currentDate < this.lastDate)
    {
      // console.log('Une section ==> '+ currentDate);
      this.lastDate = currentDate;
      return true;
    } 
    else
    { 
      this.lastDate = currentDate; 
      return false;
    }
  }

  getReceiver(recipientid){
    if(this.projectId){
      this.authService.getRecipientsBySender(this.projectId, recipientid).subscribe(res => {
        var tmpArrayUser = res['hydra:member'];
        this.contactsDest = [];
        for (var i = 0; i < tmpArrayUser.length; i++) {
          if(tmpArrayUser[i].id){
            this.contactsDest.push(tmpArrayUser[i]);
          }
        }
        this.contactsDest.sort(this.compare);
      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
      });
    } else {
      this.authService.getAllRecipientsBySender(recipientid).subscribe(res => {
        var tmpArrayUser = res['hydra:member'];
        this.contactsDest = [];
        for (var i = 0; i < tmpArrayUser.length; i++) {
          if(tmpArrayUser[i].id){
            this.contactsDest.push(tmpArrayUser[i]);
          }
        }
        this.contactsDest.sort(this.compare);
      }, (error) => {
        //console.log(' getTheProject subscribe error '+error.statusText);
      });
    }
  }

  getContact(){
    //this.loadContact();

    this.authService.getContactsExpInstant().subscribe(res => {
      this.contactsExp = [];
      var tmpArrayUser = res['hydra:member'];
      for (var i = 0; i < tmpArrayUser.length; i++) {
        this.contactsExp.push(tmpArrayUser[i]);
      }
      this.contactsExp.sort(this.compare);
    }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
    });

    this.authService.getContactsRecip().subscribe(res => {
      this.contactsDest = [];
      var tmpArrayUser = res['hydra:member'];
      for (var i = 0; i < tmpArrayUser.length; i++) {
        this.contactsDest.push(tmpArrayUser[i]);
      }
      this.contactsDest.sort(this.compare);
    }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
    });

  }

  /*loadContact(url?: string){
    url = url ? url : undefined;
    this.authService.getContacts(url).subscribe(res => {
      this.contacts = this.contacts.concat(res['hydra:member']);
      
      if(this.contacts.length && this.contacts[0].id != this.currentUser.id){
        this.contacts.unshift(this.currentUser);
      }
      this.contacts.sort(this.compare);

      if(res['hydra:view']['hydra:next']){
        this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
    }); 
  }*/

  compare( a, b ) {
    if ( a.givenName.toLowerCase() < b.givenName.toLowerCase() ){
      return -1;
    }
    if ( a.givenName.toLowerCase() > b.givenName.toLowerCase() ){
      return 1;
    }
    return 0;
  }

  loadProject(url?: string){
    url = url ? url : undefined;
    this.authService.getProjects(url).subscribe(res => {
      this.projectLists = this.projectLists.concat(res['hydra:member']);
      this.projectLists = this.projectLists.sort(function (a, b) {
          if (a.libelle.toLowerCase() < b.libelle.toLowerCase()) { return -1; }
          if (a.libelle.toLowerCase() > b.libelle.toLowerCase()) { return 1; }
          return 0;
      })
      if(res['hydra:view']['hydra:next']){
        this.loadProject(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
    }); 
  }

  getSujet(idProject){
    this.projectId = idProject;
    //this.search()
    if (idProject) {
      this.authService.getTheProject(idProject).subscribe(res => {
        this.projectTags = res['tags'];
        }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
      });

      /*
      this.authService.getContactsExpID(idProject).subscribe(res => {
        this.contactsExp = [];
        this.contactsExp = res['hydra:member'];
        this.contactsExp.sort(this.compare);
      }, (error) => {
      });*/

      /*
      this.authService.getContactsRecipID(idProject).subscribe(res => {
        var tmpArrayUser = res['hydra:member'];
        this.contactsDest = [];
        for (var i = 0; i < tmpArrayUser.length; i++) {
          if(tmpArrayUser[i].id){
            this.contactsDest.push(tmpArrayUser[i]);
          }
        }
        this.contactsDest.sort(this.compare);
      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
      });*/
    }
  }


  search(){
    if(this.modeDoc){
      this.launchSearchDoc()
    }else{
      this.launchSearch()
    }
  }
  
  launchSearch(ged = null){

    this.modeDoc = false;
    //if(!ged) this.hiddenSearchEngin = true;
    

    this.chosenFilter = { 
      transmitter: this.transmitter,
      recipient: this.recipient,
      projectId: this.projectId,
      dateBefore: this.dateBefore,
      dateAfter: this.dateAfter,
      dateDeadline: this.dateDeadline,
      tag: this.tag,
      statut: this.statut, 
      type: this.type,
      keyWord: (this.keyWord ? this.keyWord.trim() : null),
      smartNumber: this.smartNumber
    };
    //this.getAllMessages(this.chosenFilter)
    console.log(' launchSearch '+ JSON.stringify(this.chosenFilter) );
    this.EventLaunchSearch.emit(this.chosenFilter);

  }

  launchSearchDoc(){
    if(this.type=="http") {
      alert("Vous ne pouvez pas sélectionner le type lien pour rechercher un document");
      return;
    }
    this.modeDoc = true;

    this.chosenFilter = { 
      transmitter: this.transmitter,
      recipient: this.recipient,
      projectId: this.projectId,
      dateBefore: this.dateBefore,
      dateDeadline: this.dateDeadline,
      dateAfter: this.dateAfter,
      tag: this.tag,
      statut: this.statut,
      type: this.type, 
      keyWord: (this.keyWord ? this.keyWord.trim() : null),
      smartNumber: "instant"
    };
    //console.log(' launchSearchDoc '+ JSON.stringify(this.chosenFilter) );
    this.EventLaunchSearchDoc.emit(this.chosenFilter);

  }

  public resetSearch(){

    this.transmitter = null;
    this.recipient = null;
    this.projectId = null;
    this.dateBefore  = null;
    this.dateAfter  = null;
    this.dateDeadline = null;
    this.tag = null;
    this.statut = null;
    this.type = null;
    this.keyWord = null;
    this.smartNumber = null;
    this.launchSearch("1");

    /*
    if(this.modeDoc == false) 
    {
      this.launchSearch();
    }
    else
    {
      this.launchSearchDoc();
    }*/
    this.getContact();

  }


  public refreshInfoFilter(){
    this.getContact();
    this.loadProject();  
  }

  goBack(){
    this.router.navigate(['/project/'+this.recentProject+'/openmsg']);
    //window.history.back();
  }




}



